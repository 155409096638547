/* Custom Ant Design */
.ant-input-disabled {
  border: 1px solid #EEEEEE !important;
}

.ant-select-disabled .ant-select-selector {
  border: 1px solid #EEEEEE !important;
}

.ant-form-item-has-error .ant-input-disabled {
  background-color: #EEEEEE !important;
}

.ant-btn-link {
  color: #D29C09 !important;
}

.ant-btn-link:hover {
  opacity: 0.5;
}

.ant-btn-link:disabled {
  color: #808080 !important;
}

.ant-collapse-content {
  border-radius: 0 !important;
}

.ant-collapse-content-box {
  padding: 0 !important;
}

.ant-collapse-item {
  border-top: 1px solid #EEEEEE;
  border-bottom: none !important;
}

.ant-table {
  border-left: 1px solid #EEEEEE;
  border-right: 1px solid #EEEEEE;
}

.table-no-border .ant-table {
  border: none !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 0 16px;
  height: 50px;
}

.ant-table-column-sorters {
  padding: 0 16px !important;
}

.ant-table-thead th.ant-table-column-sort {
  background-color: #0a0d21 !important;
}

.table-header-secondary .ant-table-thead > tr > th {
  background-color: #666666 !important;
}

.table-header-student .ant-table-thead > tr > th {
  background-color: #C08D01 !important;
}

.ant-select-multiple .ant-select-selection-item {
  padding: 0 12px 0 16px !important;
  border: none !important;
  border-radius: 999px !important;
  background-color: #D29C09 !important;
  color: white !important;
}

.ant-select-multiple .anticon-close > svg {
  fill: white !important;
}

.transparent-input,
.transparent-input .ant-select-selector,
.transparent-input .ant-input-number {
  background-color: unset !important;
}

.ant-modal-content {
  border-radius: 2px !important;
}

.ant-modal-header {
  display: flex;
  align-items: center;
  padding: 0 20px !important;
  height: 50px;
  border-radius: 2px 2px 0 0 !important;
}

.student-modal .ant-modal-header {
  background-color: #333333 !important;
  color: white !important;
}

.ant-modal-title {
  width: 100%;
}

.ant-modal-footer {
  border-radius: 0 0 2px 2px !important;
}

.btn-secondary {
  background-color: #C4C4C4 !important;
  border: none !important;
}

.btn-secondary:hover {
  background-color: #D9D9D9 !important;
}

.modify-course-group-collapse {
  background-color: #FAFAFA;
}

.modify-course-group-collapse .ant-collapse-item {
  border-top: none !important;
}

.modify-course-group-collapse .ant-collapse-header {
  border: 1px solid #EEEEEE;
}

.ant-collapse-header {
  display: flex;
  align-items: center;
  height: 50px;
}

.faq-collapse .ant-collapse-header {
  height: unset;
}

.course-list-collapse {
  background-color: #EEEEEE !important;
  overflow: scroll;
  max-height: 560px;
  margin-top: 50px;
}

.course-list-collapse .ant-collapse-header {
  min-height: 50px;
  height: auto !important;
  padding: 0 40px 0 16px !important;
}

.custom-question-collapse {
  border-top: 1px solid #595959 !important;
  background-color: #666666 !important;
}

.custom-question-collapse:first-child {
  border-top: none !important;
}
.custom-header-modify-collapse {
  background-color: #0D122D !important;
}

.ant-progress-line {
  width: 80px !important;
}

.ant-progress-inner, .ant-progress-bg {
  border-radius: 4px !important;
}

.ant-tabs {
  overflow: unset !important;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  padding: 6px 16px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  border: 1px solid #f8f9ff !important;
  border-bottom: 1px solid #f0f0f0 !important;
  background-color: unset !important;
  outline: none;
}

.student-tabs .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  border: 1px solid rgba(0, 0, 0, 0) !important;
  border-bottom: 1px solid #f0f0f0 !important;
  background-color: unset !important;
}

.ant-tabs-bar {
  position: relative;
  z-index: 10;
  top: 1px;
  border-bottom: none !important;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  border: 1px solid #f0f0f0 !important;
  border-bottom: 1px solid white !important;
  background-color: white !important;
}

.ant-tabs-nav-container {
  border: none;
}

.student-tabs .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  border: 1px solid rgba(0, 0, 0, 0) !important;
  border-bottom: 1px solid white !important;
  background-color: white !important;
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #D29C09 !important;
  color: #D29C09 !important;
}

.ant-pagination-item:hover,
.ant-pagination-item:focus,
.ant-pagination-item-active {
  border-color: #D29C09 !important;
}

.ant-pagination-item:hover > a,
.ant-pagination-item:focus > a,
.ant-pagination-item-active > a {
  color: #D29C09 !important;
}

.ant-pagination-item-link-icon {
  color: #D29C09 !important;
}

.ant-tooltip-inner {
  background-color: #0D122D !important;
}

.answer-radio-group {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
}

.answer-radio-button {
  display: flex;
  align-items: center;
  margin: 8px;
  padding: 8px 16px;
  min-height: 50px;
  border: 1px solid #CCCCCC;
  border-radius: 4px !important;
}

.answer-radio-button span {
  line-height: 24px;
  white-space: pre-line;
  word-break: break-word;
}

.answer-radio-button:hover {
  color: #D29C09;
}

@media only screen and (max-width: 428px) {
  .answer-radio-button:hover {
    color: #343A40;
  }
}

.answer-radio-button::before {
  width: 0 !important;
}

.answer-radio-button > span:nth-child(2) {
  width: 100%;
}

.answer-radio-group .ant-radio-button-wrapper-checked {
  color: #fff;
  background: #D29C09 !important;
  border-color: #D29C09 !important;
}

.answer-radio-group .ant-radio-button-wrapper-checked:hover {
  color: #fff;
  background: #C08D01 !important;
  border-color: #C08D01 !important;
}

.search-company-user-button .ant-input-search-button {
  background-color: #FAFAFA !important;
  border: 1px solid #CCCCCC !important;
  color: #999999 !important;
  font-weight: 300;
}

.search-company-user-button .ant-input-search-button:hover {
  background-color: #F2F2F2 !important;
}

.ant-dropdown-menu {
  border-radius: 2px !important;
}

.ant-picker-clear {
  transform: translate(-24px, -50%) !important;
}

.ant-form-item-explain {
  font-size: 13px !important;
}

#FAQ-drap-drop tr.drop-over-downward td {
  border-bottom: 2px dashed #D29C09 !important;
}

#FAQ-drap-drop tr.drop-over-upward td {
  border-top: 2px dashed #D29C09 !important;
}

.ant-tooltip-one-line {
  max-width: unset;
}

.ant-skeleton-content .ant-skeleton-title {
  border-radius: 4px;
}

.ant-skeleton-content .ant-skeleton-paragraph li {
  border-radius: 4px;
}

.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 8px;
}

.table-cursor-pointer tbody > tr {
  cursor: pointer;
}

.container-dropdown-select .ant-typography {
  font-size: 14px !important;
}
/* Fix IE 11 */
.ant-input-password {
  align-items: center;
}

.ant-input-password .ant-input {
  padding: 0 !important;
}

.ant-table-cell-content {
  overflow: visible !important;
}

.ant-list-item:hover {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background: #FFF8E7;
}

.course_version_select {
  width: 800px !important;
  min-width: 800px !important;
}

.course_version_select .ant-select-item-option-content {
  overflow: unset !important;
  text-overflow: unset !important;
}

.course_version_select .ant-select-item-option {
  min-width: max-content;
}

.course_version_select > div > div:not(#rc_select_1_list) > div{
  overflow: unset !important;
}

@media only screen and (max-width: 428px) {
  .ant-modal-title {
    font-weight: 700;
  }
}
