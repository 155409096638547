/* Custom React Slick */
.history-course-slide .slick-list {
  margin: 0 -12px !important;
  overflow: hidden !important;
  max-width: 1400px;
}

.history-course-slide .slick-track {
  height: 394px !important;
  overflow: hidden !important;
}

@media only screen and (max-width: 1366px) {
  .history-course-slide .slick-track {
    height: 394px !important;
  }
}

@media only screen and (max-width: 428px) {
  .history-course-slide .slick-track {
    height: auto !important;
  }
}
